import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackService } from '@services/shared/back.service';

@Component({
  selector: 'app-back-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
})
export class BackButtonComponent {
  @Input() size = 40;
  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  constructor(private backService: BackService) {}
  onClick() {
    this.backService.backButtonClick();
    this.click.emit();
  }
}
